import React, { Component } from 'react'
import { connect } from "react-redux";
import { getDeviceByArtecoId, getParentServerByArtecoId, getPTZPresets, getPTZSequences, getPTZstatus, isCredentialEnabled } from '../../../reducers/serversReducer';
import { getActiveLayout, getChannelStreamMode, getActiveLayoutLock } from '../../../reducers/layoutsReducer';
import { withTranslation } from 'react-i18next';
import { ArtecoSelect } from '../../recordings/ArtecoSelect/ArtecoSelect';

import { ReactComponent as Arrow } from '../../svgIcons/freccia.svg';
import { Square as Stop } from 'react-feather';
import { ZoomIn as ZoomInIcon } from 'react-feather';
import { ZoomOut as ZoomOutIcon } from 'react-feather';
import { ReactComponent as DomeIcon } from '../../../components/svgIcons/cgController.svg';

import Spinner from '../../Spinner/Spinner';
import { forceLogin } from '../../../actions/serverActions';
import { wsControlPTZPayload } from '../../dashboard/wsActionsPayloads/wsPayloads';
import { socketEvents } from '../../../config/eventsAndStorage';
import { InstantEvent, PtzMovement, PtzPresets, PtzSequences } from '../../../helpers/serverCredentials';
import { getAuthentication } from '../../../reducers/authReducer';

const moveActions = {
  UP: "up",
  DOWN: "down",
  LEFT: "left",
  RIGHT: "right",
  ZOOM_IN: "zoom_tele",
  ZOOM_OUT: "zoom_wide",
  STOP: "stop",
  START_SEQUENCE: "start_sequence",
  STOP_SEQUENCE: "stop_sequence",
  PRESET: "preset",
}
class DomeController extends Component {

  constructor() {
    super();
    this.state = {
      showPTZpanel: true,
      hide: false
    }
  }


  // componentDidUpdate(prevProps, prevState) {
  //   const { ptzStatus } = this.props

  //   if(prevProps.ptzStatus.sequenceId !== ptzStatus.sequenceId) {
  //     this.setState({
  //       selectedSequence: ptzStatus.sequenceId
  //     })
  //   }

  //   if(prevProps.ptzStatus.presetId !== ptzStatus.presetId) {
  //     this.setState({
  //       selectedPreset: ptzStatus.presetId
  //     })
  //   }

  // }

  componentDidMount(){
    this.props.updatePtz(true)
  }
  
  movePTZ = (e) => {
    const { device } = this.props;

    const action = moveActions[e.currentTarget.dataset.action];

    if (!action) {
      e.stopPropagation();
      return false;
    }

    const movePayload = wsControlPTZPayload(device.id, action, 40);

    this.sendCommand(movePayload);
  }

  stopPTZ = () => {
    const { device } = this.props;

    const movePayload = wsControlPTZPayload(device.id, moveActions.STOP, 40);

    this.sendCommand(movePayload);
  }

  goToPreset = (selected) => {
    const { device } = this.props;

    const preset = selected.value;

    if (preset) {
      const movePayload = wsControlPTZPayload(device.id, moveActions.PRESET, 40, preset, null);

      this.sendCommand(movePayload);
    }
  }

  startSequence = (selected) => {
    const { device } = this.props;

    const sequenceId = selected.value;

    if (sequenceId) {
      const movePayload = wsControlPTZPayload(device.id, moveActions.START_SEQUENCE, 40, null, sequenceId);

      this.sendCommand(movePayload);
    }
  }

  stopSequence = () => {
    const { device, ptzStatus } = this.props;

    const selectedSequence = ptzStatus.sequenceId;
    if (!selectedSequence) { return; }

    const movePayload = wsControlPTZPayload(device.id, moveActions.STOP_SEQUENCE, 40, null, null);

    this.sendCommand(movePayload);
  }

  sendCommand = (payload) => {
    const { server } = this.props;

    const event = new CustomEvent(socketEvents.controlPTZ, {
      'detail': {
        payload: payload,
        server_id: server._id,
      }
    });

    document.dispatchEvent(event);
  }

  stop = () => {
    this.movePTZ(moveActions.STOP);
  }

  ptzStatusChanged(next, old) {
    if (!next && !old) return false;

    if ((next && !old) || (!next && old)) return true;

    if (next["ctx"] !== old["ctx"]) return true;
    if (next["sequenceStatus"] !== old["sequenceStatus"]) return true;
    if (next["sequenceId"] !== old["sequenceId"]) return true;
    if (next["presetStatus"] !== old["presetStatus"]) return true;
    if (next["presetId"] !== old["presetId"]) return true;
    if (next["ptzStatus"] !== old["ptzStatus"]) return true;
    if (next["action"] !== old["action"]) return true;
    if (next["requestedAction"] !== old["requestedAction"]) return true;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.server?.access_token !== this.props.server?.access_token ||
      // this.errorChanged(nextState.error, this.state.error) ||
      nextState.showPTZpanel !== this.state.showPTZpanel ||
      nextState.hide !== this.state.hide ||
      this.ptzStatusChanged(nextProps.ptzStatus, this.props.ptzStatus) ||
      nextProps.server !== this.props.server ||
      nextProps.device !== this.props.device
    ) {
      return true;
    }
    return false;
  }

  shouldComponentRender() {
    const { server } = this.props;
    if (!server?.capabilities?.ptz == 1 || !server?.capabilities?.ptz) {
      return false;
    }
    return true;
  }

  componentHasError = () => {
    const { error } = this.state;
    if (error) {
      return true;
    }
    return false;
  }


  showPTZPanel = () => {
    this.setState({
      showPTZpanel: true
    })
    this.props.updatePtz(true)
  }

  hidePTZPanel = () => {
    this.setState({
      showPTZpanel: false
    })
    this.props.updatePtz(false)
  }

  render() {
    const { t, ptzSequences, ptzPresets, ptzStatus,device } = this.props;
    const { showPTZpanel, hide } = this.state;

    const showTriggerClass = showPTZpanel ? 'inactive' : '';
    const hideTriggerClass = showPTZpanel ? 'active' : '';

    if (!this.shouldComponentRender()) {
      return <></>
    }

    const selectedSequence = ptzStatus.sequenceId;
    const selectedPreset = ptzStatus.presetId;

    const stopBtnClass = ptzStatus.sequenceStatus === 2 ? 'stop-button active' : 'stop-button disabled';
    const showPanelClass = showPTZpanel ? 'show' : '';

    const selectedSequenceElement = selectedSequence ? ptzSequences.find(seq => seq.id === selectedSequence) : null;
    const selectedSequenceDDValue = selectedSequenceElement ? { value: selectedSequence, label: selectedSequenceElement.name } : null;

    const selectedPresetElement = selectedPreset ? ptzPresets.find(seq => seq.id === selectedPreset) : null;
    const selectedPresetDDValue = selectedPresetElement ? { value: selectedPreset, label: selectedPresetElement.name } : null;
    const updatePtzIconPositionClass = isCredentialEnabled(InstantEvent,device) ? "" : "updatePtzIconPosition"

    if (hide) {
      return (
        <></>
      )
    }

    return (
      <>
      {
        (isCredentialEnabled(PtzMovement,device) || isCredentialEnabled(PtzPresets,device) || isCredentialEnabled(PtzSequences, device)) && (
          <>
          <div className={`ptz-controller-slick-trigger ${showTriggerClass} ${updatePtzIconPositionClass}`} onClick={this.showPTZPanel}>
          <DomeIcon />
          </div>
          <div className={`ptz-controller-slick-hide ${hideTriggerClass} ${updatePtzIconPositionClass}`} onClick={this.hidePTZPanel}>
          <DomeIcon />
          </div>
          </>
        )
      }
        
        <div className={`ptz-controller-slick ${showPanelClass}`}>
          {
            this.componentHasError() && <Spinner />
          }

          {
            !this.componentHasError() && (
              <>
                <div className='controls'>
                {
                  isCredentialEnabled(PtzMovement, device) && (
                    <>
                    <div className="upper">
                      <button
                        className={ptzStatus.requestedAction?.includes("up") ? 'active' : ''}
                        data-action="UP"
                        onMouseDown={this.movePTZ}
                        onMouseUp={this.stopPTZ}
                        onTouchStart={this.movePTZ}
                        onTouchEnd={this.stopPTZ}
                      >
                        <Arrow width={18} height={50} className='up' />
                      </button>
                    </div>
                    <div className="medium">
                      <button
                        className={ptzStatus.requestedAction?.includes("left") ? 'active' : ''}
                        data-action="LEFT"
                        onMouseDown={this.movePTZ}
                        onMouseUp={this.stopPTZ}
                        onTouchStart={this.movePTZ}
                        onTouchEnd={this.stopPTZ}
                      >
                        <Arrow width={18} height={50} className="left" />
                      </button>
                      <button
                        className={ptzStatus.requestedAction?.includes("right") ? 'active' : ''}
                        data-action="RIGHT"
                        onMouseDown={this.movePTZ}
                        onMouseUp={this.stopPTZ}
                        onTouchStart={this.movePTZ}
                        onTouchEnd={this.stopPTZ}
                      >
                        <Arrow width={18} height={50} className="right" />
                      </button>
                    </div>
                    <div className="lower">
                      <button
                        className={ptzStatus.requestedAction?.includes("down") ? 'active' : ''}
                        data-action="DOWN"
                        onMouseDown={this.movePTZ}
                        onMouseUp={this.stopPTZ}
                        onTouchStart={this.movePTZ}
                        onTouchEnd={this.stopPTZ}
                      >
                        <Arrow width={18} height={50} className='down' />
                      </button>
                    </div>
                    </>
                  )
              }
                </div>
                <div className='controls-bottom'>
                  { isCredentialEnabled(PtzMovement, device) && (
                    <div className="zoom">
                    <button
                      className={ptzStatus.requestedAction === "zoom_tele" ? 'active' : ''}
                      data-action="ZOOM_IN"
                      onMouseDown={this.movePTZ}
                      onMouseUp={this.stopPTZ}
                      onTouchStart={this.movePTZ}
                      onTouchEnd={this.stopPTZ}
                    >
                      <ZoomInIcon size={16} />
                    </button>
                    <button
                      className={ptzStatus.requestedAction === "zoom_wide" ? 'active' : ''}
                      data-action="ZOOM_OUT"
                      onMouseDown={this.movePTZ}
                      onMouseUp={this.stopPTZ}
                      onTouchStart={this.movePTZ}
                      onTouchEnd={this.stopPTZ}
                    >
                      <ZoomOutIcon size={16} />
                    </button>
                  </div>
                  )}
                  
                  <div className='preset_sequences'>
                    <button
                      onClick={this.stopSequence}
                      className={stopBtnClass}
                    >
                      <Stop size={16} />
                    </button>
                    {
                      ptzSequences && isCredentialEnabled(PtzSequences, device) &&  (
                        <ArtecoSelect
                          isSearchable={false}
                          isMulti={false}
                          options={ptzSequences.map(sequence => ({ value: sequence.id, label: sequence.name }))}
                          value={selectedSequenceDDValue}
                          onChange={this.startSequence}
                          placeholder={t('DOMECONTROLLER_PRESET_SEQUENCES')}
                        />
                      )
                    }
                    {ptzPresets && isCredentialEnabled(PtzPresets, device) && (
                      <ArtecoSelect
                        isSearchable={false}
                        isMulti={false}
                        options={ptzPresets.map(preset => ({ value: preset.id, label: preset.name }))}
                        value={selectedPresetDDValue}
                        onChange={this.goToPreset}
                        placeholder={t('DOMECONTROLLER_PRESET_PLACEHOLDER')}
                      />
                    )}
                  </div>
                </div>
              </>
            )
          }
        </div>
      </>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  const device = getDeviceByArtecoId(state, ownProps.artecoId);
  const ptzStatus = getPTZstatus(state, ownProps.artecoId);
  const ptzPresets = getPTZPresets(state, ownProps.artecoId);
  const ptzSequences = getPTZSequences(state, ownProps.artecoId);
  const server = getParentServerByArtecoId(state, ownProps.artecoId);
  const activeLayout = getActiveLayout(state);
  const activeLayoutLocked = getActiveLayoutLock(state);
  const channelStreamMode = getChannelStreamMode(state, ownProps.artecoId, 0, server);
  const auth = getAuthentication(state);

  return {
    device,
    ptzStatus,
    ptzPresets,
    ptzSequences,
    server,
    activeLayout,
    activeLayoutLocked,
    channelStreamMode,
    auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    forceLogin: (server) => dispatch(forceLogin(server)),
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DomeController))
