import React, { Component } from 'react'
import { connect } from "react-redux";
import OutsideClickHandler from 'react-outside-click-handler';

//icons
import { ChevronDown as ArrowDown } from 'react-feather';
import { ChevronUp as ArrowUp } from 'react-feather';
import { Crosshair as CrosshairIcon } from 'react-feather';
import { X as CloseIcon } from 'react-feather';
import { Zap as ThunderIcon } from 'react-feather';
import { ZapOff as ThunderOffIcon } from 'react-feather';
import { ReactComponent as Pin } from '../../../components/svgIcons/tiPinOutline.svg'
import { Shield as PrivacyIcon } from 'react-feather';
import { ShieldOff as PrivacyOffIcon } from 'react-feather';
import { ReactComponent as MetadataIcon } from '../../../components/svgIcons/metadata.svg';
import { ReactComponent as MetadataOffIcon } from '../../../components/svgIcons/metadata_off.svg';
import { ReactComponent as Dewarp } from '../../svgIcons/Dewarp.svg'

//actions
import { serverTreeToggle } from '../../../actions/serverActions';
import { getDeviceByArtecoId, getParentServerByArtecoId, getChannelRunningStatus, isCredentialEnabled, OmniaLight } from '../../../reducers/serversReducer';
import { zoomOnDevices } from '../../../actions/mapsActions';
import { getActiveLayout, getChannelStreamMode, getActiveLayoutLock, getChannel, getChannelPrivacyShown, getChannelMetadataShown, getChannelMode, getLockedFisheye } from '../../../reducers/layoutsReducer';
import { updateChannel, updateLayout, lockFisheyeInLayout, unlockFisheyeInLayout, setCameraInFullscreen } from '../../../actions/layoutActions';

//labels translation
import { withTranslation } from "react-i18next";
import PopupBadge from "./PopupBadge";
import {  showGenerateEventPopup } from "../../../actions/eventsAction";
import ArtecoPlayerShortCutActions from "../../ArtecoPlayer/ArtecoPlayerShortCutActions";
import { appPaths } from "../../../config/paths";
import { error, info, logger } from "../../../helpers/logger";
import { CamerasPrivacyBlur, UFollowView, InstantEvent, LayoutAddRemoveCameras, PeripheralsInteraction, PtzMovement, PtzPresets, PtzSequences } from "../../../helpers/serverCredentials";
import { customEvents } from '../../../config/eventsAndStorage';
import { isDesktop, isIOS, isTablet, isMobile} from "react-device-detect";
import ArtecoPlayerUFollowAction from '../../ArtecoPlayer/ArtecoPlayerUFollowAction';

const loggerScope = "PlayerContextMenu";
class PlayerContextMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      hasBeenClicked: true,
      stopped: false,
      lockDewarp: false,
    }

    this.playerCtxMenuRef = React.createRef();
    this.pinPopup = this.pinPopup.bind(this)
    this.unpinPopup = this.unpinPopup.bind(this)
  }

  componentDidMount() {
    const { activeLayout } = this.props;
    let getChannel;
    if (activeLayout) getChannel = activeLayout.channels.find(channel => channel.artecoId == this.props.artecoId)
    if (getChannel && getChannel.clicked == false) this.setState({ hasBeenClicked: false })
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.device?.artecoId !== this.props.device?.artecoId ||
      nextProps.server._id !== this.props.server._id ||
      nextProps.server.capabilities !== this.props.server.capabilities ||
      nextProps.activeLayout !== this.props.activeLayout ||
      nextProps.activeLayoutLocked !== this.props.activeLayoutLocked ||
      nextProps.channel?.artecoId !== this.props.channel?.artecoId ||
      nextProps.channel?.uFollowGui !== this.props.channel?.uFollowGui ||
      nextProps.channel?.minWebrtcStreamProfile !== this.props.channel?.minWebrtcStreamProfile ||
      nextProps.channelStreamMode !== this.props.channelStreamMode ||
      nextProps.channelPrivacyShown !== this.props.channelPrivacyShown ||
      nextProps.channelMetadataShown !== this.props.channelMetadataShown ||
      nextProps.channelMode.mode !== this.props.channelMode.mode ||
      nextProps.isOmniaLight !== this.props.isOmniaLight ||
      nextProps.additionalClass !== this.props.additionalClass ||
      nextProps.isEdgeMode !== this.props.isEdgeMode ||
      nextProps.origin !== this.props.origin ||
      nextProps.marker !== this.props.marker ||
      nextProps.isPlayback !== this.props.isPlayback ||
      nextProps.artecoId !== this.props.artecoId ||
      nextState.open !== this.state.open ||
      nextState.hasBeenClicked !== this.state.hasBeenClicked ||
      nextState.stopped !== this.state.stopped ||
      nextProps.lockedFisheye !== this.props.lockedFisheye
    ) {
      return true;
    }
    return false;
  }
  

  getUFollowGuiStatus =() => {
    const {activeLayout, device} = this.props;
    const channel = activeLayout && activeLayout.channels?.find(channel => channel.artecoId === device.artecoId);
    return channel ? channel.uFollowGui : null;
  }

  allowSendEvent = () => {
    const { server, additionalClass, channelStreamMode } = this.props;
    const isInPlayback = additionalClass == 'playback';
    const serverSupportEvents = (server.capabilities?.manageEvent_v1 == 2 && !server.offline && (channelStreamMode == 'webrtc'));
    return (serverSupportEvents && !isInPlayback);
  }

  allowLinkedPeripherals = () => {
    const { server, additionalClass, channelStreamMode } = this.props;
    const isInPlayback = additionalClass == 'playback';
    const serverSupportEvents = (server.capabilities?.manageEvent_v1 == 2 && !server.offline && (channelStreamMode == 'webrtc'));
    return (serverSupportEvents && !isInPlayback);
  }
  allowUFollow = () => {
    const { server, additionalClass, channelStreamMode,isOmniaLight, } = this.props;
    const isInPlayback = additionalClass == 'playback';
    const serverSupportEvents = (server.capabilities?.manageEvent_v1 == 2 && !server.offline && (channelStreamMode == 'webrtc') && this.getUFollowGuiStatus() && !isOmniaLight && (isDesktop && !isTablet && !isIOS));
    return (serverSupportEvents && !isInPlayback);
  }

  

  checkIds = (ids, peripherals) => {
    for (let i = 0; i < ids.length; i++) {
      const found = peripherals.find(p => p.id === ids[i].id);
      if (found) {
        return true;
      }
    }
    return false;
  }

  handleClick = (e) => {
    const isUFollowGui = window.location.pathname === appPaths.ufollowGui;
    if(isUFollowGui){
      return ;
    }
    if (this.props.origin == 'mapPopup') {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    this.closingTimer && clearTimeout(this.closingTimer);
    const isOpening = this.state.open === false;

    if (isOpening) {
      this.playerCtxMenuRef.current.parentElement.classList.add('ctxmenu-open');
    } else {
      this.playerCtxMenuRef.current.parentElement.classList.remove('ctxmenu-open');
    }

    this.setState({
      open: !this.state.open
    }, () => {
      //auto close after 14 secs
      if (!isOpening) return false;
      this.closingTimer = setTimeout(() => {

        this.playerCtxMenuRef.current && this.playerCtxMenuRef.current.parentElement.classList.remove('ctxmenu-open');

        this.setState({
          open: false
        })
      }, 14000)
    })
  }

  localize = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { server, device, zoomOnDevices, serverTreeToggle } = this.props;


    serverTreeToggle(server._id, true, true);
    zoomOnDevices([device], true);
    const event = new CustomEvent('deviceExpanded', {
      detail: { expanded: true }
    });
    document.dispatchEvent(event);
  }

  generateDetailedEvent = (namepopup) => {
    // generazione evento custom
    const { showGenerateEventPopup, device, server } = this.props;


    const popupData = {
      device: device,
      serverId: server._id,
      namepopup,
    }

    this.exitFullScreen();
    showGenerateEventPopup(popupData);
  }

  exitFullScreen = () => {

    const doc = window.document;
    this.props.updateCameraFullscreen(null);

    if (!doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement) {
      logger(info, loggerScope, "exitFullScreen::not in fullscreen");

      return false;
    }

    var requestMethod = doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;
    if (requestMethod) {
      requestMethod.call(doc);
    } else {
      logger(error, loggerScope, "exitFullScreen::error");
    }
  }

  remove = (e) => {
    let { activeLayout, device, updateLayout, updateCameraFullscreen } = this.props;

    activeLayout.channels = activeLayout.channels.filter((element) => {
      return (element.artecoId !== device.artecoId);
    }
    );

    updateCameraFullscreen(null);
    updateLayout(activeLayout);
    const event = new CustomEvent(customEvents.isFullScreen, {
      detail: {
        isFullScreen: false,
      },
    });
    document.dispatchEvent(event);
  }

  updateStreamMode = (streamMode) => {
    let { activeLayout, updateChannel, channel, updateCameraFullscreen } = this.props;

    updateChannel(activeLayout, {
      ...channel,
      streamMode: streamMode,
      webrtcStreamProfile: (streamMode === 'hls') ? channel.minWebrtcStreamProfile : channel.webrtcStreamProfile
    })

    updateCameraFullscreen(null);
     const event = new CustomEvent(customEvents.isFullScreen, {
       detail: {
         isFullScreen: false,
       },
     });
     document.dispatchEvent(event);

  }

  pinPopup() {
    this.setState({
      stopped: this.state.stopped ? false : true,
    })
    const pinInLayout = new CustomEvent(`pin-${this.props.artecoId}`);
    document.dispatchEvent(pinInLayout);
  }
  unpinPopup() {
    this.setState({
      stopped: this.state.stopped ? false : true,
    })
    const unpinInLayout = new CustomEvent(`unpin-${this.props.artecoId}`);
    document.dispatchEvent(unpinInLayout);
  }

  togglePrivacy = (e) => {
    const event = e.currentTarget;
    const action = event.dataset.action;

    let { activeLayout, updateChannel, channel } = this.props;

    updateChannel(activeLayout, {
      ...channel,
      privacyShown: (action === 'add')
    })

  }

  toggleMetadata = (e) => {
    const event = e.currentTarget;
    const action = event.dataset.action;

    let { activeLayout, updateChannel, channel } = this.props;

    updateChannel(activeLayout, {
      ...channel,
      metadataShown: (action === 'add') 
    })

  }

  HandleLockDewarp = () =>{
    const { device, lockFisheyeInLayout, lockedFisheye, unlockFisheyeInLayout } = this.props;
    const index = lockedFisheye.indexOf(device.artecoId);
    
    if(index === -1){
      lockFisheyeInLayout(device.artecoId);
      this.setState({lockDewarp: true})
    }else{
      unlockFisheyeInLayout(device.artecoId)
      this.setState({lockDewarp: false})
    }
  }

  render() {  
    const { server, device, channelStreamMode, activeLayoutLocked, channelPrivacyShown, channelMetadataShown, channelMode, origin, marker, isPlayback, isOmniaLight } = this.props;            
    const { t, additionalClass, isEdgeMode } = this.props;      

    const menuStatusClass = this.state.open ? 'open' : '';
    const lockDewarpClass = !this.state.lockDewarp ? 'locked' : '';

    const isPrivacyEnabled = isCredentialEnabled(CamerasPrivacyBlur, device);
    const isUFollowGui = window.location.pathname === appPaths.ufollowGui;
    const MenuIcon = isUFollowGui ? "" : this.state.open ? <ArrowUp size={18} /> : <ArrowDown size={18} />
    const showPrivacyTrigger = device.privacyData && device.privacyPlgEnabled && isPrivacyEnabled;
    const isRecPath = window.location.pathname === appPaths.recordings;
    const showMetadataToggle = device.metadataEnabled && ((!isRecPath && channelMode && channelMode.mode !== 'live') || isRecPath)
    const streamModeSwitch = (channelStreamMode === 'hls') ? (
      <li>
        <button className="btn btn-small icon-btn btn-switch" onClick={() => { this.updateStreamMode('webrtc') }}>
          <ThunderIcon size={16} />
          <span className="label">{t('SWITCH_STREAM_WEBRTC_NAME')}</span>
        </button>
      </li>
    ) : (
      <li>
        <button className="btn btn-small icon-btn btn-switch" onClick={() => { this.updateStreamMode('hls') }}>
          <ThunderOffIcon size={16} />
          <span className="label">{t('SWITCH_STREAM_HLS_NAME')}</span>
        </button>
      </li>
    );

    const pinUnpin = (this.state.stopped == false) ? (
      <li>
        <button className="btn btn-small icon-btn pin-btn" onClick={this.pinPopup}>
          <Pin size={16} />
          <span className="label">{t('LOCK_POPUP_IN_LAYOUT')}</span>
        </button>
      </li>
    ) : (
      <></>
    );

    const privacyTrigger = (!activeLayoutLocked && showPrivacyTrigger) ?
    ( channelPrivacyShown ? (
      <li>
        <button className="btn btn-small icon-btn" data-action="remove" onClick={this.togglePrivacy}>
          <PrivacyOffIcon size={16} />
          <span className="label">{t('REMOVE_PRIVACY')}</span>
        </button>
      </li>
    ) : (
      <li>
        <button className="btn btn-small icon-btn" data-action="add" onClick={this.togglePrivacy}>
          <PrivacyIcon size={16} />
          <span className="label">{t('SHOW_PRIVACY')}</span>
        </button>
      </li>
    )) : <></>;

    const metadataTrigger = showMetadataToggle ?(channelMetadataShown ? (
      <li>
        <button className="btn btn-small icon-btn" data-action="remove" onClick={this.toggleMetadata}>
          <MetadataOffIcon className="dashed" size={16} />
          <span className="label">{t('REMOVE_METADATA')}</span>
        </button>
      </li>
    ) : (
      <li>
        <button className="btn btn-small icon-btn" data-action="add" onClick={this.toggleMetadata}>
          <MetadataIcon size={16} />
          <span className="label">{t('SHOW_METADATA')}</span>
        </button>
      </li>
    )) : (<></>);

    const additionalClassName = additionalClass ? additionalClass : '';
    const allowSendEvent = this.allowSendEvent();
    const allowUFollow = this.allowUFollow();
    const isInTagPage = window.location.pathname === appPaths.tags;
    const isInDashbord = window.location.pathname === appPaths.dashboard;
    const isEdgeSearchClass = isEdgeMode ? 'edge-mode' : '';
    const cameraRunningStatus = getChannelRunningStatus(device.serverCodename, device.artecoId)
    const isRunning = device && (cameraRunningStatus !==null ? cameraRunningStatus === 1 : device?.running === 1);

    let shouldShowStreamSwitch = !!(!activeLayoutLocked && !isPlayback);
    // if(server.capabilities?.rawStream && channelStreamMode === 'webrtc'){
    //   shouldShowStreamSwitch = false;
    // }

    return (

      <div className={`player-ctx-menu ${menuStatusClass} ${additionalClassName} ${isEdgeSearchClass}`} ref={this.playerCtxMenuRef}>
        {
          (origin !== 'mapPopup') && (
            <span className="badge camera-name badge-danger menu-handle" onClick={this.handleClick}>
              {device.descr}
              {MenuIcon}
              {this.state.hasBeenClicked == false ?
                <PopupBadge
                  artecoId={this.props.artecoId}
                  marker={marker} /> : null
              }
            </span>
          )
        }

        {      !isInTagPage && (
          <OutsideClickHandler onOutsideClick={(e) => {
            const deviceNameToggle = e.target.closest('.menu-handle')
            if(this.state.open && !deviceNameToggle){
            this.setState({
              open: false
            })}
          }}>
            <ul className="ctx-menu">
              {this.state.hasBeenClicked == false ? pinUnpin : <></>}
              {(shouldShowStreamSwitch)? streamModeSwitch : <></>}
              { privacyTrigger}
              {!activeLayoutLocked && metadataTrigger}    
              <li>
                <button className="btn btn-small icon-btn find-btn" onClick={this.localize}>
                  <CrosshairIcon size={16} />
                  <span className="label">{t('FIND_IN_TREE')}</span>
                </button>
              </li>
                           
              {!activeLayoutLocked && isCredentialEnabled(LayoutAddRemoveCameras, device) &&          
                <li>
                  <button className="btn btn-small icon-btn btn-delete" onClick={this.remove}>
                    <CloseIcon size={16} />
                    <span className="label">{t('REMOVE_CAMERA_BTN')}</span>
                  </button>
                </li>
              }
            </ul>
            </OutsideClickHandler>
          )
        }

        {device?.enabled !== 0 && isRunning && allowUFollow && isCredentialEnabled(UFollowView, device) && (
          <ArtecoPlayerUFollowAction
            uFollowGuiCamera={device.artecoId}
          />
        )}

        {server.capabilities?.canManageCameras !== 1 && !isOmniaLight && (
          <>
            {allowSendEvent && isCredentialEnabled(InstantEvent, device) && (
              <ArtecoPlayerShortCutActions
                generateEvent={this.generateDetailedEvent}
              />
            )}
          </>
        )}

        {server.capabilities?.canManageCameras === 1 &&
          device?.enabled !== 0 &&
          isRunning && !isOmniaLight && (
            <>
              {allowSendEvent && isCredentialEnabled(InstantEvent,device) && (
                <ArtecoPlayerShortCutActions
                  generateEvent={this.generateDetailedEvent}
                />
              )}
            </>
          )}

        {this.props.device['is-fisheye'] && (isDesktop && !isMobile) && isInDashbord? 
          <div className={`badge shortcut-actions dewarp ${lockDewarpClass}`} onClick={this.HandleLockDewarp}>
            <Dewarp />
          </div>
        : <></>}   
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const device = getDeviceByArtecoId(state, ownProps.artecoId);
  const server = getParentServerByArtecoId(state, ownProps.artecoId);
  const activeLayout = getActiveLayout(state);
  const activeLayoutLocked = getActiveLayoutLock(state);
  const channel = getChannel(state, ownProps.artecoId);
  const channelStreamMode = getChannelStreamMode(state, ownProps.artecoId, 0, server);
  const channelPrivacyShown = getChannelPrivacyShown(state, ownProps.artecoId);  
  const channelMetadataShown = getChannelMetadataShown(state, ownProps.artecoId);
  const channelMode = getChannelMode(state, ownProps.artecoId); 
  const isOmniaLight = OmniaLight(state); 
  const lockedFisheye = getLockedFisheye(state);

  return {
    device,
    server,
    activeLayout,
    activeLayoutLocked,
    channel,
    channelStreamMode,
    channelPrivacyShown,
    channelMetadataShown,
    channelMode,
    isOmniaLight,
    lockedFisheye
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateLayout: (layout) => dispatch(updateLayout(layout)),
    updateChannel: (layout, channel) => dispatch(updateChannel(layout, channel)),
    zoomOnDevices: (devicesToZoom, shouldScroll) => dispatch(zoomOnDevices(devicesToZoom, shouldScroll)),
    serverTreeToggle: (serverId, serverTreeExpanded, serverTreeExpandedRecursively) => dispatch(serverTreeToggle(serverId, serverTreeExpanded, serverTreeExpandedRecursively)),
    showGenerateEventPopup: (popupData) => dispatch(showGenerateEventPopup(popupData)),
    lockFisheyeInLayout: (artecoId) => dispatch(lockFisheyeInLayout(artecoId)),
    unlockFisheyeInLayout: (artecoId) => dispatch(unlockFisheyeInLayout(artecoId)),
    updateCameraFullscreen: (change) => dispatch(setCameraInFullscreen(change)),
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PlayerContextMenu))